import './style.scss'
import {Col, Container, Row} from "react-bootstrap";
import bg from "../../assets/images/3rd-design/fact-check-bg.jpg";
import greenBull from "../../assets/images/3rd-design/green_bull.png";
import shLogo from "../../assets/images/3rd-design/sourcehat.png";
import config from "../../config";
import addresses from "../../config/addresses";
import copyBtn from "../../assets/images/fi_copy.svg";
import {BoxArrowUpRight, Linkedin} from "react-bootstrap-icons";

function FactsCheck() {

    return (
        <Container fluid className={'d-flex section-continuous align-items-center justify-content-center'} id={"roadmap"}
                   style={{
                       backgroundImage: `url(${bg})`,
                       backgroundPosition: 'top',
                       backgroundSize: '100% auto',
                       backgroundColor: 'rgba(0,0,0, 0.2)',
                       position: 'relative'
                   }}
                   id={"FactsCheck"}>
            <Container>
                <Row>
                    <Col xs={12} className={'sectionText text-center'}>
                        <h1 className={'text-primary'}>BULLRUN</h1>
                        <h3 className={'text-white'}>FACTS CHECK</h3>
                    </Col>
                </Row>
                <Row className={'my-3'}>
                    <Col md={4} style={{boxShadow: '0 4px 16px #f5bf5e'}} className={'mx-auto smAddress rounded-5 text-white py-2 d-flex flex-row'}>

                        <div className={'my-auto ms-2 w-100 text-center'}>
                            <b>Audited by SourceHat</b><br/>
                            <div className={'w-100 d-flex flex-row align-items-center justify-content-evenly'}>
                                <div><a className={'auditType me-2'} href={`https://sourcehat.com/audits/BullRun/`}
                                   target={'_blank'}><b>Token</b></a><BoxArrowUpRight />
                                </div>
                                <img src={shLogo} width={70}/>
                                <div><a className={'auditType me-2'} href={`https://sourcehat.com/audits/BullRunNFTStaking/`}
                                   target={'_blank'}><b>Staking</b></a><BoxArrowUpRight />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={'factsCheckRow py-4 mx-5'}>
                    <img src={greenBull} style={{
                        width: 250,
                        position: "absolute",
                        left: -130
                    }} />
                    <Col md={5} className={'sectionText text-end'}>
                        <p>
                            Launched with Utility, <span>check!</span><br/>
                            AUDIT completed, <span>check!</span><br/>
                            Mission & Purpose, <span>check!</span><br/>
                            Liquidity accumulation, <span>check!</span><br/>
                            Burns, <span>check!</span><br/>
                            Sustainable, <span>check!</span><br/>
                            LIQUIDITY IS FOR THE PEOPLE, <span>check!</span><br/>
                            No presale, <span>check!</span><br/>
                        </p>
                    </Col>
                    <Col md={1} className={'rightBorderGold'}></Col>
                    <Col md={1}></Col>
                    <Col md={5} className={'sectionText'}>
                        <p>
                            Stealth launch, <span>check!</span><br/>
                            LP tokens LOCKED for 10Years, <span>check!</span><br/>
                            Community driven, <span>check!</span><br/>
                            Zero tax and zero liquidity retention, <span>NO!</span><br/>
                            MEV bots, <span>NO!</span><br/>
                            Sandwich attacks, <span>NO!</span><br/>
                            Liquidity drained, <span>NO!</span><br/>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default FactsCheck;

